<template>
  <v-container fluid>
    <v-card
       elevation="0"
       class="pa-4"
      >
      <v-tabs v-model="activeTab">
        <v-tab>
          {{ $t("languagesSettings.checklists.tabs.customerService") }}
        </v-tab>
        <v-tab>
          {{ $t("languagesSettings.checklists.tabs.debtRecovery") }}
        </v-tab>
        <v-tab>
          {{ $t("languagesSettings.checklists.tabs.legalDepartment") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <CustomerServiceChecklistTab
            :translations="customerServiceChecklistTranslations"
            :languages="languages"
            @saved="saveItem"
            @removed="openRemoveDialog"
          />
        </v-tab-item>
        <v-tab-item>
          <DebtRecoveryChecklistTab
            :translations="debtRecoveryChecklistTranslations"
            :languages="languages"
            @saved="saveItem"
            @removed="openRemoveDialog"
          />
        </v-tab-item>
        <v-tab-item>
          <LegalDepartmentChecklistTab
            :translations="legalDepartmentChecklistTranslations"
            :languages="languages"
            @saved="saveItem"
            @removed="openRemoveDialog"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-if="removeVm"
      v-model="removeDialogActive"
      width="800"
    >
      <v-card>
        <v-card-title>
          {{ $t("languagesSettings.removeTranslation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("languagesSettings.areYouSureYouWantToDeleteTranslation") }} <strong>{{ removeVm['pl-PL'] }}</strong>?
        </v-card-text>
        <v-card-actions class="px-6 pb-4 justify-end">
          <v-btn
            outlined
            @click="closeRemoveDialog"
          >
            {{ $t("languagesSettings.close") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="removeItem"
          >
            <v-icon>mdi-delete</v-icon>
            {{ $t("common.remove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Service from '@/services'
import CustomerServiceChecklistTab from './Tabs/CustomerServiceChecklistTab.vue'
export default {
  name: 'ChecklistTranslationsTab',
  components: {
    CustomerServiceChecklistTab,
    DebtRecoveryChecklistTab: () => import('./Tabs/DebtRecoveryChecklistTab.vue'),
    LegalDepartmentChecklistTab: () => import('./Tabs/LegalDepartmentChecklistTab.vue')
  },
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  computed: {
    customerServiceChecklistTranslations () {
      return this.items.filter(item => item.departmentType === 0)
    },
    debtRecoveryChecklistTranslations () {
      return this.items.filter(item => item.departmentType === 1)
    },
    legalDepartmentChecklistTranslations () {
      return this.items.filter(item => item.departmentType === 2)
    }
  },
  data () {
    return {
      activeTab: 0,
      items: [],
      lastSavedItems: [],
      removeVm: null,
      removeDialogActive: false
    }
  },
  methods: {
    async getItems () {
      const { data: items } = await Service.get('/Dictionary/GetCheckLists')
      this.items = items
      this.lastSavedItems = items
    },
    async saveItem (item) {
      const languages = this.languages.map(lang => lang.nameISO)
      const translatedValues = []
      for (const prop in item) {
        if (languages.some(lang => lang === prop)) {
          translatedValues.push({
            language: prop,
            name: item[prop]
          })
        }
      }
      if (item.id) {
        await Service.put('/Dictionary/UpdateCheckList', {
          dictionaryId: item.id,
          translatedValues: translatedValues,
          departmentType: item.departmentType
        })
      } else {
        await Service.post('/Dictionary/AddCheckList', {
          translatedValues: translatedValues,
          departmentType: item.departmentType
        })
      }

      this.getItems()
    },
    openRemoveDialog (item) {
      this.removeVm = item
      this.removeDialogActive = true
    },
    closeRemoveDialog () {
      this.removeDialogActive = false
      this.removeVm = null
    },
    async removeItem () {
      await Service.delete('/Dictionary/DeleteCheckList', {
        dictionaryId: this.removeVm.id
      })

      this.getItems()
      this.closeRemoveDialog()
    }
  },
  mounted () {
    this.getItems()
  }
}
</script>
